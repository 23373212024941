export const USER_SIGN_IN = 'user-sign-in';																					// 로그인 
export const FETCH_USER_QUERY_KEY = 'fetch-user-query-key'; 												// 유저 정보 조회 
export const CHECK_LOGIN_QUERY_KEY = 'check-login-query-key'; 											// 로그인 체크

export const FETCH_VEHICLE_DATA_QUERY_KEY = 'fetch-vehicle-data-query-key'; 				//차량 진단 리스트 조회 
export const FETCH_VEHIICLE_QUERY_KEY = 'fetch-atter-vehicle-query-key';						//차량 세부 진단 조회 (+befor/after 이미지)
export const FETCH_VEHICLE_IN_DATA_QUERY_KEY = 'fetch-vehicle-in-data-query-key'; 	//차량 내부 리스트 조회 
export const FETCH_EXCEL_EXPORT_QUERY_KEY = 'fetch-excel-export-query-key'; 				//엑셀 다운로드
export const FETCH_VEHICLE_DIAG_QUERY_KEY = 'fetch-vehicle-diag-query-key'; 				//차량 진단 조회
export const FETCH_PAGINATION_QUERY_KEY = 'fetch-pagination-query-key'; 						//페이지네이션 조회
export const FETCH_VEHICLE_DAMAGE_QUERY_KEY = 'fetch-vehicle-damage-query-key'; 		//차량 손상 조회
export const FETCH_VEHICLE_DATA_QUERY_KEY_DELETE = 'fetch-vehicle-data-query-key-delete'; 

export const FETCH_STATION_QUERY_KEY = 'fetch-station-query-key';										// 스테이션 리스트 조회 
export const FETCH_STATION_ADDRESS_QUERY_KEY = 'fetch-station-address-query-key';		// 스테이션 주소 조회
export const FETCH_STATION_STATUS_QUERY_KEY = 'fetch-station-status-query-key';			// 스테이션 상태 조회

export const FETCH_MINIO_UPLOAD_QUERY_KEY = 'fetch-minio-upload-query-key';					// 이미지 업로드

export const FETCH_LIVE_STREAM_QUERY_KEY = 'fetch-live-stream-query-key';						// 라이브 스트리밍 조회