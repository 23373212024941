import { wefloLocalStorage } from "@/configs";
import { authorization } from "@/constants";
import { message } from "antd";
import axios, { AxiosError } from "axios";

export const requestMessage = async (text: any) => {
  const message = {
    "channel": import.meta.env.VITE_SLACK_CHANNEL,
    "text": `${JSON.stringify(text)}`,
  }
  const config = {
    "Content-Type": "application/json; charset=UTF-8", 
    "Authorization": `Bearer ${import.meta.env.VITE_SLACK_TOKEN}`,
  }
	try {
    await axios.post(`https://cors.bridged.cc/${`https://slack.com/api/chat.postMessage`}`,JSON.stringify(message),{headers: config})
  } catch (e) {
		console.log(e);
	}
};

export const handleQueryError = (err: AxiosError) => {
  if (err?.message === 'Network Error') {
    message.error('서버에 연결할 수 없습니다.');
  } else {
    switch (err?.response?.status) {
      case 403:
        wefloLocalStorage.removeItem(authorization);
        window.location.href = '/signin';
        break;
      default:
        const errorMessage = err?.response?.data?.data?.msg || '서버에서 에러가 발생했습니다.';
        if(err?.response?.status !== 401) requestMessage(err?.response?.data)
        message.error(errorMessage);
    }
  }
}
