import styled from "styled-components";
import { Button, List, Radio, Switch } from "antd";
import { Pagination } from 'antd';

export const ListCss: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  // height: '605px',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  overflow: 'auto',
};

export const MainPageContainer = styled.main`
  width: 100%;
  margin: 0 auto;
`;

export const ExcelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 40px;
      `)}
  ${({ theme }) =>
    theme.media.mobile(
      `
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 20px;
        margin-top: -15px;
      `,
    )}
`;

export const TagContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 40px;
      `)}
  ${({ theme }) =>
    theme.media.mobile(
      `
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 20px;
        margin-top: -15px;
      `,
    )}
`

export const ExcelIcon = styled.div`
  display: flex;
  justify-content: end;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 5px;
  /* border: solid 1px rgba(255, 255, 255, 0.78); */
  cursor: pointer;
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin: 0 auto;
    ${({ theme }) =>
      theme.media.mobile(
        `
          column-gap: 8px;
          font-size: 10px;
        `,
      )}  
  }
  p {
    font-family: 'Suit medium', 'Inter medium';
    font-size: 14px;
    color: rgba(255, 255, 255, 0.78);
    ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 26px;
      `,
    )}
  }
  .ant-btn-default:disabled{
    color: rgb(222 222 222 / 83%) !important;
  }
`;

export const StyleSwitch = styled(Switch)`
  width: 100%;
  margin-right: 0;
  font-size: 30px !important;
`;

export const StyledList = styled(List)`
  row-gap: 15px;
  .ant-list{
    background-color: transparent !important;
  }
  .ant-list-header {
    padding-block: 0px;
  }
  .ant-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-spin-nested-loading{
    overflow: auto;
    height: 100%;
  }
  .ant-spin-nested-loading::-webkit-scrollbar{
    display: none;
  }
  .ant-list-empty-text{
    height: 350px;
  }
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 25px;
      `)}
  ${({ theme }) =>
      theme.media.mobile(
      `
        margin-top: 0px;
        padding: 0px !important;
      `,
  )}
`;

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  label {
    font-family: 'Suit medium', 'Inter medium';
    font-size: 12px;
    color: rgba(255, 255, 255, 0.78);
  }
`;

export const Lists = styled.div`
  display: grid;
  grid-template-columns: 90% 5% 5%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SectionHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: gray;
    font-size: 13px;
  }
  margin-bottom: 40px;
`;

export const SectionTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
`;

export const TestFields = styled.div<{ username: string }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) => (props.username !== 'weflo' ? '5% 14% 20% 8% 26% 5% 7% 6%' : '2% 5% 14% 20% 8% 26% 5% 5% 6%')};
  grid-column-gap: 1%;
  margin: 0 auto;
  label {
    font-family: 'Suit bold', 'Inter medium';
    font-size: 15px;
    font-weight: 600;
    display: flex;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.78);
    text-indent: 15px;
  }
  ${({ theme, username }) =>
      theme.media.monitor(
      `
        grid-template-columns: ${username !== 'weflo' ? '5% 12% 17% 11% 28% 5% 8% 7%' : '3% 5% 12% 17% 11% 28% 5% 6% 6%'};
        label {
          font-size: 18px;
        }
      `)}
  ${({ theme, username }) =>
    theme.media.tablet(
      `
      font-size: 12px;
      grid-template-columns: ${username !== 'weflo' ? '5% 13% 16% 9% 23% 9% 6% 10%' : '3% 5% 13% 16% 9% 23% 8% 6% 8%'};
      label {
        font-family: 'Suit medium', 'Inter medium';
        font-size: 12px;
        font-weight: 400;
      }
      `,
    )}
      ${({ theme, username }) =>
        theme.media.mobile(
          `
          text-indent: 0px;
          font-size: 11px;
          grid-column-gap: 0%;
          grid-template-columns: ${username !== 'weflo' ? '11% 20% 18% 17% 22% 6% 6%' : '4% 11% 19% 17% 17% 18% 8% 6%'};
          padding-left: 5px;
          padding-right: 5px;
          label {
            font-family: 'Suit regular', 'Inter regular';
            font-size: 9px;
            font-weight: 200;
            text-indent: 1px;
          }
          `,
        )}
`;

export const TestLog = styled.li<{ isVisited: boolean, username: string }>`
  background: rgba(255, 255, 255, 0.7);;
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;
  height: 45px;
  display: grid;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  grid-template-columns: ${(props) => (props.username !== 'weflo' ? '5% 14% 20% 8% 26% 5% 7% 6%' : '2% 5% 14% 20% 8% 26% 5% 6% 5%')};
  grid-column-gap: 1%;
  border-radius: 5px;
  transition: 0.1s ease all;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.78);
  text-overflow: ellipsis;
  opacity: ${(props) => (props.isVisited ? 0.7 : 1)};
  ${({ theme, username }) =>
      theme.media.monitor(
      `
        height: 60px;
        grid-template-columns: ${username !== 'weflo' ? '5% 12% 17% 11% 28% 5% 8% 7%' : '3% 5% 12% 17% 11% 28% 5% 6% 6%'};
        margin-bottom: 15px;
      `)}
  ${({ theme, username }) =>
      theme.media.tablet(
      `
        grid-template-columns: ${username !== 'weflo' ? '5% 13% 16% 9% 23% 9% 6% 10%' : '3% 5% 13% 16% 9% 23% 8% 6% 8%'};
      `,)}
    ${({ theme, username }) =>
      theme.media.mobile(
      `
        height: 58px;
        grid-template-columns: ${username !== 'weflo' ? '11% 20% 18% 17% 22% 6% 6%' : '5% 11% 18% 17% 17% 20% 5% 7%'};
        padding-left: 10px;
        padding-right: 5px;
      `,
      )}
`;

export const TestItem = styled.p`
  text-indent: 5px;
  font-family: 'Suit medium', 'Inter medium';
  color: rgba(0, 0, 0, 0.78);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  overflow: hidden;
  word-break: keep-all;
  font-size: 15px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        text-indent: 20px;
        font-size: 19px;
      `)}
  ${({ theme }) =>
    theme.media.tablet(
      `
      font-size: 12px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
			font-size: 10px;
      white-space: normal;
      text-align: start;
      justify-content: start;
      text-indent: 0px;
      overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 1.2;
        text-align: left;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2 ;
        -webkit-box-orient: vertical;
      `,
    )}
    @media screen and (min-width: 420px) and (max-width: 660px) {
    font-size: 12px;
    }
`;

export const StyledRadio = styled(Radio)`
.ant-radio-checked .ant-radio-inner{
  border-color: white !important ;
  background-color: rgb(90, 39, 219) !important;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: white !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: white !important;
  background-color: rgb(90, 39, 219) !important;
}
`

export const StyledButton = styled(Button)`
  background: rgba(0, 14, 42, 0.78);
  color: white;
  border: none;
  font-size: 12px; 
  font-family: 'Suit medium';
  cursor: pointer;
  transition: 0.1s ease all;
  &:hover {
    background: rgba(0, 14, 42, 0.78);
    color: white !important;
  }
`

export const Status = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 auto;
`;

export const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`

export const StyledPagination = styled(Pagination)`
color: rgba(255, 255, 255, 0.5) !important;
.ant-pagination{
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  color: rgba(255, 255, 255, 0.5) !important;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
}
svg {
  color: #ffffff !important;

}
.ant-pagination-item-active{
  border-color: transparent !important;
  background-color: transparent !important;
}
.ant-pagination-item-active a{
  color: #ffffff !important;
  font-weight: 700;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
}
.ant-select-selector {
  background: transparent !important;
  border: none !important;
}
.ant-select-selection-item{
  color: rgba(255, 255, 255, 0.6) !important;
}
`;

export const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 100px;
      `,
    )}
`;
export const ButtonStyle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  width: 5%;
  height: 100%;
`;

export const ScrollButton = styled.button``;